import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../asyncComponent';

const HomePage = asyncComponent(() => import('../HomePage'));
const Products = asyncComponent(() => import('../Products'));
const AboutUs = asyncComponent(() => import('../AboutUs'));
const Partners = asyncComponent(() => import('../Partners'));
const Brands = asyncComponent(() => import('../Brands'));
const Lab = asyncComponent(() => import('../Lab'));

const UnAuthenticatedRoutes = ({ language, setOpen }) => {

  return (
    <div onClick={() => setOpen(false)}>
      <Switch>
        <Route exact path='/'>
          <HomePage language={language} />
        </Route>
        <Route exact path='/products'>
          <Products language={language} />
        </Route>
        <Route exact path='/aboutUs'>
          <AboutUs language={language} />
        </Route>
        <Route exact path='/partners'>
          <Partners language={language} />
        </Route>
        <Route exact path='/brands'>
          <Brands language={language} />
        </Route>
        <Route exact path='/lab'>
          <Lab language={language} />
        </Route>
        <Redirect to='/' />
      </Switch>
    </div>

  );
};

export default UnAuthenticatedRoutes;
