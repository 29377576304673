import React from 'react';

const NotFoundPage = () => {

  return (
      <div>
          Not found
      </div>
  );
};
export default NotFoundPage;
