import React, { useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
import {
    useRouterLocation,
} from '../../hooks/routerHooks.js';
import asyncComponent from '../asyncComponent';
import UnAuthenticatedRoutes from './UnAuthenticatedRoutes';
import ErrorBoundary from '../ErrorBoundary';
import MainWrapper from './styled/MainWrapper';

const Header = asyncComponent(() => import('../Main/Partials/header.js'));
const Footer = asyncComponent(() => import('../Main/Partials/footer.js'));

const AppRouter = () => {
    const { pathname } = useRouterLocation();
    const [language, setLanguage] = useLocalStorage('language', 'am');
    const [open, setOpen] = useState(false);
    const showHeader = !['/not-found', '/terms-conditions'].includes(pathname);
    const waitBeforeShow = pathname === '/products' || pathname === '/brands' || pathname === 'partners' ? 5000 : 4000


    return (
        <ErrorBoundary>
            <MainWrapper>
                {showHeader && <Header setLanguage={setLanguage} language={language} open={open} setOpen={setOpen} />}
                <UnAuthenticatedRoutes language={language} open={open} setOpen={setOpen} />
                <Footer language={language} open={open} setOpen={setOpen} waitBeforeShow={waitBeforeShow} />
            </MainWrapper>
        </ErrorBoundary>

    );
};
export default AppRouter;
