import React, { Suspense } from 'react';

export default function asyncComponent(importComponent) {
  const Component = React.lazy(importComponent);
  return (props) => {
    const fallback = (
        <div style={{ height: '100%', width: '100%'}} />
    );

    return (
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    );
  };
}
