import styled from "styled-components";

const MainWrapper = styled.div`
{
    width: 100%;
    }

    .showcase {
      position: absolute;
      width: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: space-between;
      align-items; center;
      background: black;
      color: white;
      z-index: 2;
      object-fit: cover;
    }

    .parent-header {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }

    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 2;
      @media (max-width: 1380px) {
        padding: 40px;
      }
      @media (max-width: 1268px) {
        padding: 30px;
      }
      @media (max-width: 912px) {
        padding: 20px;
      }
    }

    .header-menu-language {
      margin-top: -10px;
      margin-right: 50px;
    }

    .header-icon {
      cursor: pointer;
      margin: 5px;
      width: 30px;
      height: 30px;
      @media (max-width: 1380px) {
        width: 25px;
        height: 25px;
      }
      @media (max-width: 1268px) {
        width: 20px;
        height: 20px;
      }
    }

    .header-media-row-common {
      position: absolute;
      margin-right: 110px;
      z-index: 2;
      right: 0;
      float: right;
      @media (max-width: 1380px) {
        margin-right: 90px;
      }
      @media (max-width: 1268px) {
        margin-right: 85px;
      }
      @media (max-width: 912px) {
        margin-right: 75px;
      }
      @media (max-width: 850px) {
        margin-right: 75px;
      }
      @media (max-width: 510px) {
        margin-right: 30px;
      }
    }

    .header-media-row-lab {
      position: absolute;
      margin-right: 30px;
      z-index: 2;
      right: 0;
      float: right;
      margin-right: 110px;
      @media (max-width: 1380px) {
        margin-right: 90px;
      }
      @media (max-width: 1268px) {
        margin-right: 85px;
      }
      @media (max-width: 912px) {
        margin-right: 75px;
      }
      @media (max-width: 850px) {
        margin-right: 75px;
      }
      @media (max-width: 510px) {
        margin-right: 20px;
      }
    }

    .header-media-row {
      position: absolute;
      margin-right: 115px;
      margin-top: 270px;
      top: 500;
      right: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @media (max-width: 1380px) {
        margin-right: 50px;
      }
      @media (max-width: 1268px) {
        margin-right: 70px;
      }
      @media (max-width: 912px) {
        margin-right: 70px;
      }
      @media (max-width: 850px) {
        margin-right: 50px;
      }
      @media (max-width: 510px) {
        margin-right: 20px;
      }
    }

    .header-logo {
      width: 30%;
      margin-right: 200px;
      cursor: pointer;
      @media (max-width: 1199.98px) {
        margin-right: 120px;
        width: 25%;
      }
      @media (max-width: 768px) {
        margin-right: 20px;
      }
    }

    .ant-menu-horizontal {
      border-bottom: none;
      line-height: 24px;
    }

    .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
      border: none;
    }

    .ant-menu-vertical {
      border-right: none;
    }
    
    .ant-menu-submenu-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-menu-submenu-arrow {
      display: none;
    }

    .ant-menu-title-content {
      font-size: 24px;
      @media (max-width: 1492px) {
        font-size: 16px;
      }
      @media (max-width: 1201px) {
        font-size: 12px;
      }
      @media (max-width: 1026px) {
        font-size: 8px;
      }
    }

    .ant-menu-item-selected {
      color: white;
    }

    .collapse {
      width: 100%;
      display: flex;
    }

    .toggle {
      position: relative;
      width: 60px;
      height: 60px;
    }

    .showcase video{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.8;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }

    .text {
      position: absolute;
      top: 30%;
      margin: 0px 900px 0 80px;
      z-index: 2;
      text-overflow: ellipsis;
      color: white;
      @media (max-width: 1380px) {
        margin: 0px 800px 0 50px;
        white-space: normal;
      }
      @media (max-width: 1268px) {
        margin: 0px 650px 0 40px;
      }
      @media (max-width: 1150px) {
        margin: 0px 550px 0 30px;
      }
      @media (max-width: 920px) {
        margin: 0px 450px 0 25px;
      }
      @media (max-width: 850px) {
        margin: 0px 350px 0 20px;
      }
      @media (max-width: 768px) {
        margin: 0px 250px 0 20px;
      }
      @media (max-width: 510px) {
        margin: 0px 150px 0 20px;
      }
    }


    .text h2 {
      font-size: 1.1em;
      margin-top: 100px;
      color: white;
      text-transform: uppercase;
      @media (max-width: 1199.98px) {
        font-size: 0.9em;
      }
      @media (max-width: 850px) {
        font-size: 0.8em;
      }
      @media (max-width: 768px) {
        font-size: 0.7em;
      }
      @media (max-width: 510px) {
        font-size: 0.6em;
      }
    }

    .footer-partners-row {
      width: 100%;
      display:block;
      text-align: center;
    }

    .footer {
      width: 100%;
      padding: 24px;
      background: rgba(11, 36, 82);
    }

    .footer-cols {
      text-align: start;
    }
    
    .footer-cols-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
    }

    .footer-icon{
      cursor: pointer;
      width: 70px;
      height: 70px;
      @media (max-width: 1380px) {
        width: 50px;
        height: 50px;
      }
      @media (max-width: 1268px) {
        width: 30px;
        height: 30px;
      }
    }

    .footer-media-row{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer-partners-col {
      display:block;
      text-align: center;
    }

    .footer-row {
      margin-top: 24px;
      width: 100%;
      text-align: center;
    }

    .footer-text {
      font-size: 14px;
      color: white;
      @media (max-width: 1254px) {
        font-size: 12px;
      }
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }

    .news-title {
      height: 80px;
      width: 100%;
      background: rgba(11, 36, 82);
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1380px) {
        height: 60px;
      }
      @media (max-width: 1268px) {
        height: 40px;
      }
    }

    .icon-banner {
      width: 70px;
      height: 70px;
      @media (max-width: 1380px) {
        width: 50px;
        height: 50px;
      }
      @media (max-width: 1268px) {
        width: 30px;
        height: 30px;
      }
    }

    .icon-banner-1 {
      width: 30px;
      height: 30px;
      @media (max-width: 1380px) {
        width: 20px;
        height: 20px;
      }
      @media (max-width: 1268px) {
        width: 10px;
        height: 10px;
      }
    }

    
    .lab-icon-banner {
      width: 150px;
      height: 25px;
      @media (max-width: 1380px) {
        width: 120px;
        height: 20px;
      }
      @media (max-width: 1268px) {
        width: 96px;
        height: 16px;
      }
    }

    .partners-icon-banner {
      width: 100px;
      height: 70px;
      @media (max-width: 1380px) {
        width: 70px;
        height: 50px;
      }
      @media (max-width: 1268px) {
        width: 50px;
        height: 30px;
      }
    }
  
    .news-title-text {  
      font-size: 22px;
      color: rgba(255, 250, 250, 250);
      @media (max-width: 1380px) {
          font-size: 18px;
        }
      @media (max-width: 1268px) {
          font-size: 14px;
        }
    }

    .footer-text-2 {
      margin-top: 24px;
      margin-left: 9px;
      width: 100%;
      text-align: center;
      color: rgba(255, 250, 250, 0.4);
    }
  }

}`;

export default MainWrapper;
