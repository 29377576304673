import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import 'antd/dist/antd.css';

class App extends Component {
    render() {
        return (
            <div>
                <BrowserRouter><AppRouter /></BrowserRouter>
            </div>
        );
    }
}

export default App;
